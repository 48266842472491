$theme-colors: (
  'primary': #79B94e,
);

.callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code {
    border-radius: 3px;
  }
  & + .bs-callout {
    margin-top: -5px;
  }
}

@each $name, $color in (default, #777), (primary, #79B94e), (success, #5cb85c), (danger, #ff0000),
  (warning, #ff9900), (info, #5bc0de), (bdc, #29527a)
{
  .callout-#{$name} {
    border-left-color: $color;
    h4 {
      color: $color;
    }
  }
}

@import '~bootstrap/scss/bootstrap';

/* THEME OVERRIDE */

$primary: #79B94e;
$secondary: #777 !important;
$success: #5cb85c !important;
$info: #5bc0de !important;
$warning: #ff9900 !important;
$danger: #ff0000 !important;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

a {
  color: $secondary;

  &:active,
  &:hover {
    color: $primary;
  }
}

.breadcrumb {
  background-color: inherit;
  font-size: small;
  text-transform: uppercase;

  &-item.active {
    color: $primary;
  }
}

.card {
  border-radius: 10px;
  margin: 20px 0;
  overflow: hidden;
}
.card-header-tabs {
  margin-bottom: -1.7rem;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  margin-top: -1.2rem;
}

.highlighted-card {
  border-radius: 5px;
  font-size: 24px;
  font-weight: bold;
  margin: 0 -7px 14px -7px;
  padding: 10px 18px;
  white-space: nowrap;

  &.bg-primary {
    background-color: transparentize(#79B94e, 0.8) !important;
    color: $primary;
  }
  &.bg-secondary {
    background-color: transparentize(#777, 0.8) !important;
    color: $secondary;
  }
  &.bg-success {
    background-color: transparentize(#5cb85c, 0.8) !important;
    color: $success;
  }
  &.bg-info {
    background-color: transparentize(#5bc0de, 0.8) !important;
    color: $info;
  }
  &.bg-warning {
    background-color: transparentize(#ff9900, 0.8) !important;
    color: $warning;
  }
  &.bg-danger {
    background-color: transparentize(#ff0000, 0.8) !important;
    color: $danger;
  }

  & .subtext {
    color: $secondary;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: normal;
  }
}

.nav-tabs {
  justify-content: center;
  padding: 8px;

  .nav-link {
    &:hover {
      border: none;
      border-bottom: 3px solid $primary;
      color: $primary;
    }

    &.active {
      border: none;
      border-bottom: 3px solid $primary;
      color: $primary;
      font-weight: bold;
    }
  }
}
